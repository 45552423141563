/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerPurchaseQualification = params => axios({
    url: '/api/dealer/customer/dealerPurchaseQualification/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerPurchaseQualification = params => axios({
    url: '/api/dealer_purchase_qualification/system/dealerPurchaseQualification/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerPurchaseQualification = params => axios({
    url: '/api/dealer_purchase_qualification/system/dealerPurchaseQualification/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerPurchaseQualification = params => axios({
    url:'/api/dealer/customer/dealerPurchaseQualification/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerPurchaseQualification = params => axios({
    url: '/api/dealer_purchase_qualification/system/dealerPurchaseQualification/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
